import { post } from '@/http'

// 退换货订单详情
export function getExOrderDetail(data) {
    return new Promise((resolve, reject) => {
        post('/ExOrder/GetExOrderDetail', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 申请售后
export function applyEx(data) {
    return new Promise((resolve, reject) => {
        post('/ExOrder/ApplyEx', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
