import { post } from '@/http'
// 退换货订单详情
export function getExOrderDetail(data) {
    return new Promise((resolve, reject) => {
        post('/ExOrder/GetExOrderDetail', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 查询物流,返回Json
export function queryInformations(data) {
    return new Promise((resolve, reject) => {
        post('/ExOrder/QueryInformation', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

