import { post } from '@/http'
// 退货回填物流信息
export function sendTrack(data) {
    return new Promise((resolve, reject) => {
        post('/ExOrder/SendTrack', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 取消退换申请
export function cancleExOrder(data) {
    return new Promise((resolve, reject) => {
        post('/ExOrder/CancleExOrder', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 退换货订单详情
export function getExOrderDetail(data) {
    return new Promise((resolve, reject) => {
        post('/ExOrder/GetExOrderDetail', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
