import { post } from '@/http'

// 获取订单信息
export function getOrderInfo(data) {
    return new Promise((resolve, reject) => {
        post('/Order/GetOrderInfo', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 取消订单
export function cancleOrder(data) {
    return new Promise((resolve, reject) => {
        post('/Order/CancleOrder', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
// 提醒发货
export function sendRemind(data) {
    return new Promise((resolve, reject) => {
        post('Order/SendRemind', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
// 查询物流,返回Json
export function queryInformation(data) {
    return new Promise((resolve, reject) => {
        post('Order/QueryInformation', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 确认收货
export function agreenOrder(data) {
    return new Promise((resolve, reject) => {
        post('Order/AgreenOrder', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 发起支付
export function aliPay(data) {
    return new Promise((resolve, reject) => {
        post('Order/AliPay', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 对公账户信息
export function getCorporateInfo(data) {
    return new Promise((resolve, reject) => {
        post('Order/GetCorporateInfo', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 对公账户信息
export function WeChatPay(data) {
    return new Promise((resolve, reject) => {
        post('Order/WeChatPay', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

// 对公账户信息
export function getOrderWordInfo(data) {
    return new Promise((resolve, reject) => {
        post('Order/GetOrderWordInfo', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
