<template>
	<div v-if="order">
		<div class="top">
			<div class="top-left">
				<div class="state">
					<div class="state-label">订单状态：</div>
					<div class="state-text">
						<span v-if="ExOrder.State == 0 || ExOrder.State == 10">商家处理中</span>
						<span v-if="ExOrder.State == 1">
							审核通过
							<span v-if="show" class="time">还剩{{ order.time }}</span>
						</span>
						<span v-if="ExOrder.State == 2">
							审核失败
							<span v-if="show" class="time">还剩{{ order.time }}</span>
						</span>
						<span v-if="ExOrder.State == 3">待收货验货</span>
						<span v-if="ExOrder.State == 4">待收货验货</span>
						<span v-if="ExOrder.State == 5">退款成功</span>
						<span v-if="ExOrder.State == 6">检验合格</span>
						<span v-if="ExOrder.State == 7">检验不合格</span>
						<span v-if="ExOrder.State == 8">收料有误</span>
						<span v-if="ExOrder.State == 9">取消申请</span>
					</div>
				</div>
				<div v-if="ExOrder.State == 0" class="agree">
					<div class="agree-text">您已成功发起退款申请，请耐心等待商家处理</div>
					<div style="margin-top: 30px">
						您还可以
						<!-- <el-button size="mini" class="edit" @click="edit()"
                            >修改申请</el-button
                        > -->
						<el-button size="mini" @click="cancel()">撤销申请</el-button>
					</div>
				</div>
				<div v-if="ExOrder.State == 1" class="agree">
					<div class="agree-text">卖家已同意您的退款/退货申请，请退货并填写物流信息逾期未填写， 退货申请将关闭</div>
					<div style="margin-top: 15px">
						您可以
						<el-button size="mini" class="edit" @click="cancel()">撤销申请</el-button>
						<el-button size="mini" @click="toLogistics">填写退货物流单号</el-button>
					</div>
				</div>
				<div v-if="ExOrder.State == 2" class="agree">
					<div class="agree-text">卖家拒绝了您当前的退款申请，你可在售后期内修改申请理由和凭据， 再次发起申请，逾期不处理视为放弃申请</div>
					<div style="margin-top: 30px">
						您还可以
						<el-button size="mini" class="edit" @click="edit()">修改申请</el-button>
						<el-button size="mini" @click="cancel()">撤销申请</el-button>
					</div>
				</div>
				<div v-if="ExOrder.State == 3 || ExOrder.State == 4" class="agree"><div class="agree-text">您已提交退货信息，请耐心等待商家收到货物后处理</div></div>
				<div v-if="ExOrder.State == 5" class="agree"><div class="agree-text">卖家已同意退款，退款金额将返回原支付渠道，预计7个工作日 到账，请注意查收。</div></div>
			</div>
			<div class="top-right">
				<div class="top-right-text">
					<div>1.提交订单</div>
					<div>2.售后审核</div>
					<div>3.买家退货</div>
					<div>4.退款完成</div>
				</div>
				<div class="top-right-icon">
					<div>
						<div :class="['icon-main border', { active: state => 0 && state <= 8 }]"><i class="iconfont icontijiao-"></i></div>
					</div>
					<div>
						<div :class="['icon-main border', { active: state >= 1 && state <= 8 }]"><i class="iconfont iconshenhe"></i></div>
					</div>
					<div>
						<div :class="['icon-main border', { active: state >= 3 && state <= 8 }]"><i class="iconfont icontuihuowuyou"></i></div>
					</div>
					<div>
						<div :class="['icon-main ', { active: state == 5 }]"><i class="iconfont icontuikuan"></i></div>
					</div>
				</div>
			</div>
		</div>
		<div class="logistics" v-if="state == 2">
			<div class="logistics-top">驳回说明</div>
			<table border="1" cellspacing="0" cellpadding="0">
				<tr class="logistics-text">
					<td width="1050">
						<div class="addr">
							<div class="addr-bottom">{{ ExOrder.ErrorRemaek }}</div>
						</div>
					</td>
				</tr>
			</table>
		</div>
		<div class="logistics" v-if="state == 1">
			<div class="logistics-top">退货地址</div>
			<table border="1" cellspacing="0" cellpadding="0">
				<tr class="logistics-text">
					<td width="1050">
						<div class="addr">
							<div class="addr-top">
								<div>收货人：{{ order.ExUserNamePhone }}</div>
								<div>收货地址：{{ order.ExAddress }}</div>
							</div>
							<div class="addr-bottom">
								<div>
									<span>需将纸质发票与商品一同寄回，</span>
									未与商家协商一致，请勿使用到付或平邮，以免商家拒签货物
								</div>
								<div>请完整填写真实退货物流信息，逾期未填写，退货申请将关闭，关闭后若超出保障期，将无法再次发起售后申请</div>
							</div>
						</div>
					</td>
				</tr>
			</table>
		</div>
		<div class="logistics" v-if="(state == 3 || state == 5) && ExOrder.ExType != 2">
			<div class="logistics-top">退货物流</div>
			<table border="1" cellspacing="0" cellpadding="0">
				<tr class="logistics-text">
					<td width="200">物流公司</td>
					<td width="825">{{ express ? express.ComName : '' }}</td>
				</tr>
				<tr class="logistics-text">
					<td width="200">运单号码</td>
					<td width="825">{{ ExOrder.TrackingNum }}</td>
				</tr>
				<tr class="logistics-text">
					<td width="200">物流跟踪</td>
					<td width="825" v-if="express">
						<div v-for="(item, index) in express.TrackList" :key="index">
							<div>{{ item.ftime }} {{ item.context }}</div>
						</div>
					</td>
				</tr>
			</table>
		</div>
		<div class="list">
			<div>
				<table border="0" cellspacing="0" cellpadding="0">
					<tr class="list-title">
						<th width="450">商品信息</th>
						<th width="268">单价(元)</th>
						<th width="200">数量</th>
						<th width="200">优惠</th>
						<th width="200">实付款(元)</th>
					</tr>
				</table>

				<!--订单号-->
				<table style="border-collapse:separate; border-spacing:0 10px;" border="0" cellspacing="0" cellpadding="0">
					<tr class="order-mian" style="margin:10px">
						<td width="450" style="padding-left:10px">
							<span class="order-number" style="color:#999">{{ ExOrder.CreatTime }}</span>
							| 订单号 {{ ExOrder.OrderID }}
						</td>
						<td width="268"></td>
						<td width="200" v-for="j in 3" :key="j"></td>
					</tr>
					<tr class="order-mian-list" v-for="(j, i) in ItemList" :key="i">
						<td style="display: flex" class="border">
							<div><img style="margin-left:10px" :src="pic + j.ExDetail.ProPictrue" alt="" /></div>
							<div class="order-mian-list-text">
								<div class="proName">{{ j.ExDetail.ProName }}</div>
								<div>{{ j.ProductOrderDetai.BarCode }}</div>
							</div>
						</td>
						<td class="border">
							<div>￥{{ j.ProductOrderDetai.ProUnitPrice }}</div>
						</td>
						<td class="border">
							<div>X {{ j.ProductOrderDetai.PCount }}</div>
						</td>
						<td class="border">
							<div>- {{ j.ProductOrderDetai.DisPrice }}</div>
						</td>
						<td class="border">
							<div class="subtotal">
								<div>￥{{ j.ProductOrderDetai.LastPrice }}</div>
								<div class="subtotal-tax">(含税费￥{{ (j.ProductOrderDetai.TaxPrice * j.ProductOrderDetai.PCount).toFixed(2) }})</div>
							</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<div class="logistics">
			<div class="logistics-top">退款信息</div>
			<table border="1" cellspacing="0" cellpadding="0">
				<tr class="logistics-text">
					<td width="200">退款编号</td>
					<td width="825">{{ ExOrder.ExOrderID }}</td>
				</tr>
				<tr class="logistics-text">
					<td width="200">退款件数</td>
					<td width="825">{{ ItemList[0].ExDetail.PCount }} 件</td>
				</tr>
				<tr class="logistics-text">
					<td width="200">退款金额</td>
					<td width="825">
						<div style="color: #DC2310">￥{{ ExTotalPrice }}</div>
					</td>
				</tr>
				<tr class="logistics-text">
					<td width="200">退款方式</td>
					<td width="825">原支付方式退回</td>
				</tr>
				<tr class="logistics-text">
					<td width="200">退款原因</td>
					<td width="825">{{ ExOrder.Remark }}</td>
				</tr>
				<tr class="logistics-text">
					<td width="200">货物状态</td>
					<td width="825">
						<div v-if="ExOrder.TrackType == 1">未收到货</div>
						<div v-else>已收到货</div>
					</td>
				</tr>
				<tr class="logistics-text">
					<td width="200">退款说明</td>
					<td width="825">{{ ExOrder.ExRemark }}</td>
				</tr>
			</table>

			<div
				class="cart"
				@click="toChat"
				style="background-color:#0097ba ;width: 180px;
				height: 40px;
				text-align: center;
				line-height: 40px;
				float: right;
				margin-top: 20px;color:#fff;
				cursor: pointer;"
			>
				联系客服
			</div>
		</div>
		<!--        <div class="logistics">-->
		<!--            <div class="logistics-top">-->
		<!--                发票信息-->
		<!--            </div>-->
		<!--            <table border="1" cellspacing="0" cellpadding="0" v-if="order.InvoiceType >  0 ">-->
		<!--                <tr class="logistics-text">-->
		<!--                    <td width="200">-->
		<!--                        发票状态-->
		<!--                    </td>-->
		<!--                    <td width="825" style="color: #0097BA">-->
		<!--                        <span>已开票</span>-->
		<!--                    </td>-->
		<!--                </tr>-->
		<!--                <tr class="logistics-text">-->
		<!--                    <td width="200">-->
		<!--                        发票内容-->
		<!--                    </td>-->
		<!--                    <td width="825">-->
		<!--                        商品明细-->
		<!--                    </td>-->
		<!--                </tr>-->

		<!--                <tr class="logistics-text">-->
		<!--                    <td width="200">-->
		<!--                        发票类型-->
		<!--                    </td>-->
		<!--                    <td width="825">-->
		<!--                        <div v-if="order.InvoiceType == 2 || order.InvoiceType == 3" class="payment-list-button">-->
		<!--                            增值税专用发票-->
		<!--                            <span></span>-->
		<!--                        </div>-->
		<!--                        <div v-if="order.InvoiceType == 1" class="payment-list-button">-->
		<!--                            增值税专用发票-->
		<!--                        </div>-->
		<!--                        <div v-if="order.InvoiceType == 3" class="payment-list-info">-->
		<!--                            个人-->
		<!--                        </div>-->
		<!--                        <div v-if="order.InvoiceType == 2 || order.InvoiceType == 1" class="payment-list-info">-->
		<!--                            单位-->
		<!--                        </div>-->
		<!--                    </td>-->
		<!--                </tr>-->
		<!--                <tr class="logistics-text">-->
		<!--                    <td width="200">-->
		<!--                        发票抬头-->
		<!--                    </td>-->
		<!--                    <td width="825">-->
		<!--                        {{ order.InvoiceTitle }}-->
		<!--                    </td>-->
		<!--                </tr>-->
		<!--                <tr class="logistics-text" v-if="order.InvoiceType == 1 || order.InvoiceType == 2">-->
		<!--                    <td width="200">-->
		<!--                        公司税号-->
		<!--                    </td>-->
		<!--                    <td width="825">-->
		<!--                        {{ order.DutyParagraph }}-->
		<!--                    </td>-->
		<!--                </tr>-->
		<!--            </table>-->
		<!--        </div>-->
		<!--        <div class="total">-->
		<!--            <div class="total-left">-->
		<!--                <div class="total-left-list"><span>支付方式：</span> <span>手机支付</span></div>-->
		<!--                <div class="total-left-list"><span>支付平台：</span> <span>支付宝</span></div>-->
		<!--                <div class="total-left-list"><span>支付平台流水单号：</span> <span>2020112422001445671435403901</span></div>-->
		<!--                <div class="total-left-list"><span>支付时间：</span> <span>2020-11-24 10:54:12</span></div>-->
		<!--            </div>-->
		<!--            <div class="total-right" v-if="order.DetailList">-->
		<!--                <div class="total-right-item">-->
		<!--                    <div class="total-right-item-lable">-->
		<!--                        <span class="number">{{ order.DetailList.length }}</span>件商品，总商品金额：-->
		<!--                    </div>-->
		<!--                    <div class="ct">￥{{ order.p4 }}</div>-->
		<!--                </div>-->
		<!--                <div class="total-right-item">-->
		<!--                    <div class="total-right-item-lable">税费：</div>-->
		<!--                    <div class="ct">￥{{ order.p2 }}</div>-->
		<!--                </div>-->
		<!--                <div class="total-right-item">-->
		<!--                    <div class="total-right-item-lable">运费：</div>-->
		<!--                    <div class="number">免运费</div>-->
		<!--                </div>-->
		<!--                <div class="total-right-item">-->
		<!--                    <div class="total-right-item-lable">优惠券：</div>-->
		<!--                    <div class="number">￥{{ order.p3 }}</div>-->
		<!--                </div>-->
		<!--                <div class="total-right-item">-->
		<!--                    <div class="total-right-item-lable">实付款：</div>-->
		<!--                    <div class="pay">￥{{ order.p1 }}</div>-->
		<!--                </div>-->
		<!--            </div>-->

		<!--        </div>-->
	</div>
</template>

<script>
import { agreenOrder, queryInformation } from '../payment/service';
import { getExOrderDetail } from '../orderDetails/service';
import { cancleExOrder } from '../logistics/service';
import { queryInformations } from './service';

export default {
	data() {
		return {
			pic: FILE_URL,
			lastPrice: 0,
			show: false,
			express: {},
			information: [],
			order: null,
			state: null,
			TaxPrice: '',
			cartList: []
		};
	},
	mounted() {
		this.OrderID = this.$route.query.OrderID;
		this.init();
	},
	methods: {
		toChat() {
			window.open('https://mcs.hexagonmetrology.cn/im/text/0hnzjp.html')
			// let j = this.order.ExOrderDataList[0].ItemList[0].ProductOrderDetai;
			// this.ysf('product', {
			// 	show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
			// 	title: '退款编号：' + this.ExOrder.ExOrderID,
			// 	desc: '退款商品：' + j.ProName + '退款件数：' + this.ItemList[0].ExDetail.PCount + '件 / 退款金额：'+this.ExTotalPrice+' / 退款方式：原支付方式退回 / 货物状态：' + (this.ExOrder.TrackType == 1 ? '未收到货' : '已收到货') ,
			// 	picture: FILE_URL +j.ProPictrue,
			// 	note: '退款原因：' + (this.ExOrder.Remark ? this.ExOrder.Remark : '无'),
			// 	success: () => {
			// 		// 成功回调
			// 		this.ysf('open');
			// 	},
			// 	error: () => {
			// 		// 错误回调
			// 		this.ysf('open');
			// 	}
			// });
		},
		edit() {
			let j = this.order.ExOrderDataList[0].ItemList[0].ProductOrderDetai;
			this.$router.push({
				path: `/typeGoods?ExOrder=${this.order.ExOrderDataList[0].ExOrder.ExOrderID}&CreatTime=${j.CreatTime}&OrderID=${j.OrderID}&ProName=${j.ProName}&ProUnitPrice=${
					j.ProUnitPrice
				}&PCount=${j.PCount}&DisPrice=${j.DisPrice}&LastPrice=${j.LastPrice}&ProPictrue=${j.ProPictrue}&BarCode=${j.BarCode}&TaxPrice=${j.TaxPrice}&PID=${j.PID}`
			});
		},
		// 取消申请
		cancel() {
			this.$confirm('你确定要执行吗, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let data = {
					ExOrderID: this.order.ExOrderDataList[0].ExOrder.ExOrderID
				};
				cancleExOrder(data).then(() => {
					this.$message.success('提交成功');
					this.$router.go(-1);
				});
			});
		},
		toLogistics() {
			let item = this.order.ExOrderDataList[0];
			let j = this.order.ExOrderDataList[0].ItemList[0].ExDetail;
			let i = this.order.ExOrderDataList[0].ItemList[0].ProductOrderDetai;
			this.$router.push({
				path: `/me/logistics?CreatTime=${item.ExOrder.CreatTime}&OrderID=${j.ExOrderID}&ProName=${j.ProName}&ProUnitPrice=${j.ExPrice}&PCount=${j.PCount}&DisPrice=${
					i.DisPrice
				}&LastPrice=${i.LastPrice}&ProPictrue=${j.ProPictrue}&BarCode=${j.BarCode}&TaxPrice=${i.TaxPrice}&PID=${j.PID}`
			});
		},
		init() {
			getExOrderDetail({
				ExOrderID: this.OrderID
			}).then(res => {
				this.order = res.data;
				this.state = this.order.ExOrderDataList[0].ExOrder.State;
				this.ExOrder = this.order.ExOrderDataList[0].ExOrder;
				this.ItemList = this.order.ExOrderDataList[0].ItemList;
				this.ExTotalPrice = res.data.ExTotalPrice;
				if (res.data.ExOrderDataList[0].ExOrder.State == 1 || res.data.ExOrderDataList[0].ExOrder.State == 2) {
					setInterval(() => {
						this.show = false;
						let dateTime = new Date(res.data.ExOrderDataList[0].ExOrder.CreatTime);
						dateTime = dateTime.setDate(dateTime.getDate() + 7);
						dateTime = new Date(dateTime);
						this.order.time = this.showtime(dateTime);
						this.show = true;
					}, 1000);
				}
				// 请求物流接口
				if (res.data.ExOrderDataList[0].ExOrder.State == 3 || res.data.ExOrderDataList[0].ExOrder.State == 5) {
					queryInformations({
						ExOrderID: res.data.ExOrderDataList[0].ExOrder.ExOrderID
					}).then(res => {
						this.express = res.data;
					});
				}
			});
		},
		// 确认收货
		confirm() {
			agreenOrder({
				OrderID: this.order.OrderID
			}).then(() => {
				this.$message.success('收货完成');
				this.$router.go(-1);
				this.init();
			});
		},
		showLogistics() {
			if (!this.order.TrackingNum) {
				return;
			}
			queryInformation({
				OrderID: this.order.TrackingNum
			}).then(res => {
				this.information = res.data;
			});
		},
		showtime(data) {
			let nowtime = new Date(), //获取当前时间
				endtime = new Date(data); //定义结束时间
			let lefttime = endtime.getTime() - nowtime.getTime(), //距离结束时间的毫秒数
				days = parseInt(lefttime / 1000 / 60 / 60 / 24), //计算剩余的天数
				lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24), //计算小时数
				leftm = Math.floor((lefttime / (1000 * 60)) % 60), //计算分钟数
				lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
			if (lefts.length == 1) {
				lefts = '0' + lefts;
			}
			lefth = lefth >= 0 && lefth < 10 ? '0' + lefth : '' + lefth;
			leftm = leftm >= 0 && leftm < 10 ? '0' + leftm : '' + leftm;
			lefts = lefts >= 0 && lefts < 10 ? '0' + lefts : '' + lefts;
			this.time = lefth + '小时' + leftm + '分' + lefts + '秒';
			if (lefth.startsWith('-')) {
				return '00小时00分00秒';
			} else {
				this.order.time = days + '天' + lefth + '小时' + leftm + '分' + lefts + '秒';
				return days + '天' + lefth + '小时' + leftm + '分' + lefts + '秒';
			}
		}
	}
};
</script>

<style lang="less" scoped>
.order-number {
	color: #333333;
}

.payment {
	span {
		color: #e35d51;
	}
}

.edit {
	background: #1b98aa;
	border: none;
	color: #ffffff;
}

.state-label {
	margin-top: 3px;
}

.agree-text {
	font-size: 13px;
	color: #666666;
}

.handle {
	margin: 10px auto;
	padding: 5px 15px;
	color: #ffffff;
	background: #dc2310;
	cursor: pointer;
	color: #ffffff;
}

.addr {
	margin-left: -20px;
}

.addr-top {
	border-bottom: 1px solid #dcdcdc;
	padding: 0 20px;
}

.addr-bottom {
	padding: 0 20px;

	span {
		margin-left: 0 !important;
		color: #dc2310 !important;
	}
}

.time {
	font-size: 14px;
	font-family: '微软雅黑';
	margin-left: 20px;
}

.total {
	background: #f5f5f5;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	padding: 40px 30px;
	box-sizing: border-box;

	.total-right-item {
		display: flex;
		margin: 20px 0;

		.total-right-item-lable {
			width: 250px;
			text-align: right;
			padding-right: 20px;
			color: #666666;
			margin-right: 60px;
		}

		.pay {
			font-family: 'ct';
			color: #dc2310;
			font-size: 22px;
		}
	}

	.total-left-list {
		margin: 30px 0;
		width: 500px;
		color: #666666;
	}

	.total-right-label {
		background: red;
		width: 300px;
	}

	.total-right {
		.number {
			font-family: 'ct';
			color: #dc2310;
			margin: 0 10px;
		}
	}

	.ct {
		font-family: 'ct';
	}

	.total-left-list-addr {
		display: flex;
	}

	.button {
		padding: 2px 10px;
		color: #666666;
		border: 1px solid #bfbfbf;
		margin-left: 20px;
	}

	.info {
		margin-left: 10px;
	}

	.addrs {
		width: 500px;
		margin-left: 20px;
	}
}

.refund {
	cursor: pointer;
}
.proName {
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.logistics {
	text-align: left;
	margin: 10px 0;

	.logistics-top {
		line-height: 40px;
		height: 40px;
		background: #f9f9f9;
		padding-left: 20px;
		box-sizing: border-box;
		font-family: 'ct';
	}

	table {
		border: 1px solid #f4f4f4;
		border-collapse: collapse;
		border-spacing: 0;
	}

	.logistics-text {
		font-family: '微软雅黑';
		font-size: 13px;
		color: #666666;

		span {
			color: #1b98aa;
			cursor: pointer;
			margin-left: 10px;
		}

		td {
			height: 40px;
			line-height: 40px;
			padding-left: 20px;
			border-top: none;
		}
	}
}

.list {
	margin-top: 10px;

	.list-title {
		background: #eeeeee;
		height: 40px;
		text-align: center;
	}

	.order-mian {
		width: 1300px;
		height: 30px;
		line-height: 30px;
		font-size: 12px;
		margin: 10px 0;
		background: #f9f9f9;
		padding-left: 10px;
		box-sizing: border-box;
	}

	.order-mian-list {
		text-align: center;
		font-size: 12px;
		margin: 10px 0;
	}

	.order-mian-list-text {
		text-align: left;
		margin: 10px;
	}

	.subtotal {
		font-family: 'ct';
		color: #1b98aa;
		font-size: 17px;

		.subtotal-tax {
			font-family: '微软雅黑';
			font-size: 12px;
			color: #999999;
		}
	}

	.button {
		width: fit-content;
		padding: 2px 10px;
		margin: 10px auto;
		background: rgba(27, 152, 170, 0);
		border: 1px solid #d9d9d9;
		color: #999999;
	}

	img {
		width: 100px;
		height: 100px;
	}

	.border {
		padding-bottom: 10px;
		border-bottom: 1px solid #f4f4f4;
	}

	.border-left {
		border-left: 1px solid #f4f4f4;
	}

	.border-right {
		border-right: 1px solid #f4f4f4;
		border-bottom: 1px solid #f4f4f4;
	}

	.order-details {
		margin-top: 30px;
	}

	.paidui {
		color: #999999;

		span {
			margin-left: 5px;
		}
	}

	.handle {
		width: fit-content;
		margin: 10px auto;
		padding: 5px 15px;
		color: #ffffff;
		background: #dc2310;
	}
}

.top {
	display: flex;
	background: #fdfdfd;
	border: 1px solid #dcdcdc;
	padding: 20px 20px;
	width: 1009px;

	> div {
		width: 50%;
	}

	.top-left {
		border-right: 1px solid #dcdcdc;

		> div {
			margin-bottom: 20px;
		}

		.state-text {
			font-size: 18px;
			color: #dc2310;
			margin-left: 20px;
			font-family: 'ct';
		}

		.view {
			color: #666666;

			button {
				color: #ffffff;
				background: #0097ba;
				border: 1px solid #dcdcdc;
				padding: 5px 10px;
				outline: none;
				cursor: pointer;
			}
		}
	}

	.top-right-text {
		display: flex;
		justify-content: center;
		color: #333333;
		text-align: center;

		> div {
			width: 90px;
			margin: 0 10px;
		}
	}

	.top-right-icon {
		display: flex;
		justify-content: center;
		margin-top: 10px;

		> div {
			width: 90px;
			margin: 0 10px;
		}

		.border {
			position: relative;

			&:after {
				position: absolute;
				content: '';
				width: 60px;
				height: 1px;
				background: #d9d9d9;
				top: 25px;
				left: 50px;
			}
		}

		.icon-main {
			width: 50px;
			height: 50px;
			background: #dcdcdc;

			text-align: center;
			line-height: 50px;
			border-radius: 50%;
			margin-left: 21px;
			margin-top: 15px;

			i {
				color: #ffffff;
			}
		}

		.active {
			background: #1b98aa;
			color: #999999;
		}

		i {
			font-size: 30px;
		}
	}

	.state {
		display: flex;
	}

	.top-right-date {
		display: flex;
		margin-top: 14px;
		justify-content: center;
		color: #999999;
		text-align: center;
		font-size: 12px;

		> div {
			width: 90px;
			margin: 0 10px;
		}
	}
}
</style>
